@use '../../assets/styles/mixins' as mixin;

.containerPropos {
    align-items: center;
    justify-content: center;
    border-left: 3px solid var(--orange);
    @include mixin.xs{
        flex-direction: column;
    }
    
    .containerButton{
        width: 150px;
        padding: 10px ;
        @include mixin.sm-lt{
            width: 120px;
        }

        button{
            width: 150px;
            height: 40px;
            font-family: var(--font-family);
            border: 3px solid var(--gray-1) ;
            border-radius: 5px;
            font-weight: 500;
            @include mixin.sm-lt{
                width: 120px; 
            }
            &:hover{
                color: var(--orange);
                border: 3px solid var(--orange);
            }
        }
        .buttonOpen{
            border: 3px solid var(--orange);
        }
    }
    .textOpen{
        width: 600px;
        height: 110px ;
        @include mixin.sm-lt{
            height: auto;
        }
        @include mixin.xs{
            width: 100%;
        }
        
        .text{
            padding:15px;
            @include mixin.sm-lt{
                padding: 10px;
            }
            h3{
                font-size: 18px;
                @include mixin.sm-lt{
                    font-size: medium;
                }
            }
            h4{
                margin: 5px 0 ;
                font-weight: 500;
                font-size: 15px;
                @include mixin.sm-lt{
                    font-size: 13px;
                }
            }
            p{
                margin:5px 0 0 0;
                font-size: 15px;
                @include mixin.sm-lt{
                    font-size: small;
                }
            }
            
        }
    }
}