@use '../../assets/styles/mixins' as mixin;

    .containerTechno {
        align-items: center;
        padding: 50px 0 ;
        @include mixin.md-lt{
            flex-direction: column;
            padding: 30px 0;
        }
        
        
    img{
        object-fit: cover;
        height: 270px;
        width: 550px;
        border: 5px solid var(--gray-1);
        border-radius: 5px; 
        @include mixin.xs{
            height: 200px;
         }
        
    }
        .containerParagraphe{
            padding: 20px;
            margin: 20px;
            border: 10px solid var(--orange);
            height: min-content;
            width: 50%;
            box-shadow: var(--box-shadow);
            background-color: var(--gray-1);
            color: var(--color);
            border-radius: 5px;
            @include mixin.md-lt{
                width: 100%;
            }
        }
    }
    .reverse:nth-child(2n+1){
            flex-direction: row-reverse;
            position: relative;
            @include mixin.md-lt{
                flex-direction: column;
            }
    }
