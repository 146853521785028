@use '../../assets/styles/mixins' as mixin;

.footer{
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    color: var(--color);
    background-color: var(--nav);
    height: 100px;
    font-size: small;
    position: relative;
    @include mixin.xs{
      padding:0 100px 0 20px;
  }
    .arrow{
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border: 3px solid var(--orange);
        border-radius: 5px;
        cursor: pointer;
        position: absolute;
        left: 90%;
        bottom: 20%;
        transform: translate(-90%, -20%);
        @include mixin.xs{
          width: 40px;
          height: 40px;
      }
        i{
          color: var(--color); 
          font-size: 25px;
          @include mixin.xs{
            font-size: 18px;
        }
          &:hover{
            color: var(--orange);
          }
        }
    }
    h3{
      @include mixin.xs{
        font-size: 10px;
        font-weight: 400;
    }
    }
    
}